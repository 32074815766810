.App {
  text-align: center;
}

.arabic{
  direction: rtl;
  line-height: 1.5;
}
.dropdown-menu{
  min-width: 25vw;
}
.arabic.nav_btn{
  text-align: center!important;
}
.nav_btn{
  white-space: initial!important;
  /* color: #222222!important;
  background-color: #EFEFEF!important; */
}
.row{
  margin: 0;
  object-fit: contain;
}
ul{
  object-fit: contain;
}
.reviewerNameContainer{
  position: relative;
}
.reviewerName{
  color: white;
  background-color: #999999;
  font-size: 20px;
  padding: 10px 50px;
  border-radius: 25px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.overlay-white{
  background-color: rgba(255, 255, 255, 0.774);
  width: 100%;
  height: 100%;
}
.overlay-black{
  color: white;
  background-color: rgba(0, 0, 0, 0.575);
  width: 100%;
  height: 100%;
}
.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 3000ms cubic-bezier(1, 1, 1, 1) 300ms!important;
  background-color: #fff;
}
.MuiSvgIcon-fontSizeLarge {
    font-size: 3.1875rem!important;
}
.carousel-item {

  transition: transform 2s ease-in-out;
}
.carousel-indicators{
display: none!important;
}
.carousel-control-next{
  display: none!important;
}
.carousel-control-prev{
  display: none!important;
}
.ticker{
  position: -webkit-sticky; /* Safari */
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  top: 50px;
}
.mail{
    position:fixed;
    bottom:15px;
    right:15px;
    height:65px;
    width:65px;
    color:#3D3D3D;
    background-color:white;
    border:2px solid #3D3D3D;
    border-radius:50%;
    text-align:center;
    line-height:65px;
    z-index:5;
    transition: all 0.5s ease-in-out;
}
.mail:hover{
color: white;
background-color: #3D3D3D;
transition: all 0.5s ease-in-out;
}


/* from timeline */
.timeline-bg{
  /* background-image: url("../../../images/webp/image3.webp")!important; */
  background-repeat: no-repeat;
  /* background-position: left; */
  background-size: contain;
  color: rgb(43, 41, 41);
}
.a_remove_style,.a_remove_style:hover{
  color: #222222;
  text-decoration: none;
}
.custom-timeline {
  margin-left: 20px;
}
li{
  text-align: justify;
}
p,b,li{
  font-size: 1rem;
  margin-bottom: 5px;
}
.arabic li, .arabic b{
  font-size: 1.4rem;
  margin-bottom: 5px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  position: absolute;
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  padding-top: 9px;
  color: #222222;
  margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
  margin-left: 24px;
}
/* timeline css ends here */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.mini-title{
  font-size: 1.5rem;
}

.designation{
  /* margin: 20px 0; */
  
}
.mini-subtitle{
  padding: 0 0px;
}
.App-link {
  color: #61dafb;
}
.oneRem{
font-size: 1rem;
}
hr{
  margin-top: 0;
  margin-bottom: 0;
}
.oneFifthRem{
  /* font-size: 1.5rem; */
}
.educationalCertification b{
  font-size: 1.5rem;
}
.arabic .oneOneRem,.arabic.oneOneRem{
  font-size: 1.4rem;
}
.col-md-6,.col-md-3,.col-md-4,.col-sm-12{
  padding: 0%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
